import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

// Image
import Image2 from "../../assets/images/photo-2.jpg";
import { Button } from "../../components/button";
import { UList } from "../../components/ulist";

const SectionContainer = styled.section`
  ${tw`
    container pt-20
  `}

  h2 {
    ${tw`
      mb-12
    `}
  }

  img {
    ${tw`
     hidden md:block
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-2 gap-4
  `}
`;

const GridCol = styled.div`
  ${tw`
     px-10
  `}

  h2 {
    ${tw`
      mb-6
    `}
  }

  p {
    ${tw`
      text-black
    `}
  }
`;

export function Section5() {
  return (
    <SectionContainer>
      <Grid>
        <img src={Image2} alt="Symfonia" />
        <GridCol>
          <h2>
            Odkryj <span className="text-green">funkcjonalności</span> programu
            Symfonia dla finansów i księgowości
          </h2>
          <p className="mb-8">
            W komfortowy sposób wymienisz dane online z&nbsp;programem Symfonia
            ERP Handel i&nbsp;zautomatyzujesz codzienną pracę.
          </p>
          <UList
            list={[
              "Obsługa złożonych procesów biznesowych",
              "Wzorcowe plany kont, układ RZiS i bilansu dla różnych działalności gospodarczych",
              "VAT, CIT, PIT, JPK, e-sprawozdania, przelewy",
              "Podatkowa księga przychodów i rozchodów (KPiR), rozliczenia ryczałtowe, karta podatkowa",
              "Raporty i analizy",
              "E-faktury, odczyt danych z faktur za pomocą OCR",
            ]}
          />
          <Button text={"Sprawdź"} url={"#sec-2"} anchor={true} />
        </GridCol>
      </Grid>
    </SectionContainer>
  );
}

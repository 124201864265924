import React from "react";
import "./App.scss";
import { LandingPage } from "./app/containers/LandingPage";
import { Footer } from "./app/components/footer";

function App() {
  return (
    <div className="App">
      <LandingPage />
      <Footer />
    </div>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

// Image
import Icon1 from "../../assets/images/tile-1.svg";
import Icon2 from "../../assets/images/tile-2.svg";
import Icon3 from "../../assets/images/tile-3.svg";

const SectionContainer = styled.section`
  ${tw`
    container pt-20 text-center
  `}

  h2 {
    ${tw`
      mb-12
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-3 gap-4
  `}
`;

const GridCol = styled.div`
  ${tw`
    bg-white shadow-xl px-5 py-10
  `}

  h3 {
    ${tw`
      mb-4
    `}
  }

  p {
    ${tw`
      text-black
    `}
  }
`;

const IconContainer = styled.div`
  ${tw`
    flex items-center justify-center mx-auto h-28 mb-7
  `}
`;

export function Section4() {
  return (
    <SectionContainer>
      <div className="flex justify-center">
        <div className="md:w-10/12">
          <h2>
            Co <span className="text-green">zyskujesz</span>{" "}
            dzięki&nbsp;wdrożeniu oprogramowania Symfonia?
          </h2>
        </div>
      </div>
      <Grid>
        <GridCol>
          <IconContainer>
            <img src={Icon1} alt="1" />
          </IconContainer>
          <h3>Dostęp do&nbsp;wiedzy na&nbsp;poziomie eksperckim</h3>
          <p>Oprogramowanie aktualizowane pod&nbsp;okiem specjalistów</p>
        </GridCol>
        <GridCol>
          <IconContainer>
            <img src={Icon2} alt="1" />
          </IconContainer>
          <h3>Wszystkie dane w&nbsp;jednym miejscu</h3>
          <p>Efektywne zarządzanie obiegiem dokumentów elektronicznych</p>
        </GridCol>
        <GridCol>
          <IconContainer>
            <img src={Icon3} alt="1" />
          </IconContainer>
          <h3>Agregowanie danych w&nbsp;BI (Business Intelligence)</h3>
          <p>Łączenie wartości w&nbsp;danych oraz&nbsp;analiza danych</p>
        </GridCol>
      </Grid>
    </SectionContainer>
  );
}

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

// Image
import Image3 from "../../assets/images/photo-3.jpg";
import { Button } from "../../components/button";
import { UList } from "../../components/ulist";

const SectionContainer = styled.section`
  ${tw`
    container pt-20
  `}

  h2 {
    ${tw`
      mb-12
    `}
  }

  img {
    ${tw`
     hidden md:block
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-2 gap-4
  `}
`;

const GridCol = styled.div`
  ${tw`
    px-5
  `}

  h2 {
    ${tw`
      mb-4
    `}
  }

  p {
    ${tw`
      text-black
    `}
  }
`;

export function Section6() {
  return (
    <SectionContainer>
      <Grid>
        <GridCol>
          <h2>
            Wybierz <span className="text-green">więcej możliwości</span>{" "}
            programu Symfonia ERP Handel oraz&nbsp;Symfonia ERP Business
            Intelligence
          </h2>
          <p className="mb-8">
            Obsługa zakupów, sprzedaży i&nbsp;magazynowania ma teraz lepsze
            dopasowanie do&nbsp;specyfiki Twojej branży.
          </p>
          <UList
            list={[
              "Monitoring informacji o należnościach i zobowiązaniach",
              "Optymalizacja procesu sprzedaży",
              "Personalizacja pól, formularzy, dokumentów",
              "Gotowe schematy analiz",
              "Integracja z platformami e-commerce i sklepami internetowymi",
              "Baza informacji ostanie magazynu i wartości towarów",
              "Pełna kontrola wszystkich transakcji sprzedażowych",
            ]}
          />
          <Button text={"Sprawdź"} url={"#sec-2"} anchor={true} />
        </GridCol>
        <img src={Image3} alt="Symfonia" />
      </Grid>
    </SectionContainer>
  );
}

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

interface UlistProps {
  list: string[];
}

const ListContainer = styled.ul`
  ${tw`
    m-0 mb-9
  `}
`;

const ListItem = styled.li`
  ${tw`
    relative pl-7 text-black text-lg block
  `}
  &:before {
    content: "";
    ${tw`
      block bg-green w-2 h-2 rounded-full absolute left-0 top-2 transform
    `}
  }
`;

export function UList(props: UlistProps) {
  return (
    <ListContainer>
      {props.list.map((item: string, index: number) => (
        <ListItem key={index}>{item}</ListItem>
      ))}
    </ListContainer>
  );
}

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

// Images
import Logo from "../../assets/images/symfonia.svg";
import Image1 from "../../assets/images/photo-1.jpg";
import { Button } from "../../components/button";

const SectionContainer = styled.section`
  ${tw`
    container md:grid flex flex-col grid-cols-2 relative
  `}
`;

const LogoContainer = styled.div`
  ${tw`
    mb-12
  `}
`;

const SectionContent = styled.div`
  ${tw`
    pt-10 md:pr-20
  `}
`;

export function Section1() {
  return (
    <SectionContainer>
      <SectionContent>
        <LogoContainer>
          <img src={Logo} alt="symfonia" />
        </LogoContainer>
        <p className="mb-5">
          Nowoczesne oprogramowanie symfonia dla&nbsp;deweloperów i&nbsp;firm
          budowlanych
        </p>
        <h1 className="mb-7">
          Wkrocz w&nbsp;<span className="text-green">nową erę</span> zarządzania
          projektami, finansami i&nbsp;sprzedażą
        </h1>
        <p className="mb-14">
          Przygotuj swoją firmę na&nbsp;przyszłość dzięki&nbsp;kompleksowemu,
          bezpiecznemu i&nbsp;wygodnemu rozwiązaniu do&nbsp;obsługi złożonych
          procesów biznesowych.
        </p>
        <Button text={"Dowiedz się więcej"} url={"#sec-2"} anchor={true} />
      </SectionContent>
      <div>
        <img src={Image1} alt="symfonia" />
      </div>
    </SectionContainer>
  );
}

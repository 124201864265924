import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const SectionContainer = styled.section`
  ${tw`
    container pt-32 text-center
  `}

  h2 {
    ${tw`
      mb-14
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-3
  `}
`;

const GridCol = styled.div`
  ${tw`
    px-8 pt-5 pb-10 border-b-2 border-gray-200
  `}
  &:nth-last-of-type(-n+3) {
    ${tw`
      md:border-0
    `}
  }
  &:nth-of-type(3n + 1),
  &:nth-of-type(3n + 2) {
    ${tw`
      md:border-r-2 border-gray-200
    `}
  }
  h4 {
    ${tw`
      mb-4 text-green leading-normal
    `}
  }

  p {
    ${tw`
      text-black
    `}
  }
`;

export function Section7() {
  return (
    <SectionContainer>
      <div className="flex justify-center">
        <div className="md:w-10/12">
          <h2>
            Oprogramowanie Symfonia. Zaprojektowane, aby&nbsp;
            <span className="text-green">pomóc</span> Ci w&nbsp;prowadzeniu
            Twojego biznesu
          </h2>
        </div>
      </div>
      <Grid>
        <GridCol>
          <h4>
            25 <br />
            lat
          </h4>
          <p>
            doświadczenia we&nbsp;współpracy <br />
            z&nbsp;sektorem MSP
          </p>
        </GridCol>
        <GridCol>
          <h4>
            200 <br />
            partnerów
          </h4>
          <p>
            technologicznych <br />
            i&nbsp;biznesowych
          </p>
        </GridCol>
        <GridCol>
          <h4>
            16 <br />
            województw
          </h4>
          <p>
            we wszystkich <br />
            regionach Polski
          </p>
        </GridCol>
        <GridCol>
          <h4>Aktualizacje</h4>
          <p>
            Najnowsze wersje oprogramowania,
            <br />
            dodatki, monitoring zmian prawnych
          </p>
        </GridCol>
        <GridCol>
          <h4>Nagrody</h4>
          <p>
            Najlepszy produkt dla&nbsp;MSP, Innowacyjna Firma, Best in Cloud, IT
            Future Award, Status Supermarka, Najlepszy dostawca oprogramowania
            księgowego
          </p>
        </GridCol>
        <GridCol>
          <h4>Rekomendacje</h4>
          <p>
            Członkostwo w&nbsp;Digital Poland i&nbsp;certyfikacje Stowarzyszenia
            Księgowych w&nbsp;Polsce
          </p>
        </GridCol>
      </Grid>
    </SectionContainer>
  );
}

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

// Images
import Logo from "../../assets/images/symfonia.svg";

const SectionContainer = styled.section`
  ${tw`
    container pt-32 text-center
  `}

  h2 {
    ${tw`
      mb-14
    `}
  }
`;

const Grid = styled.div`
  ${tw`
    grid md:grid-cols-4 gap-4
  `}
`;

const GridCol = styled.div`
  ${tw`
    flex items-center justify-center py-8
  `}

  img {
    filter: grayscale(100%);
  }

  a {
    &:hover {
      img {
        ${tw`
         grayscale-0
        `}
      }
    }
  }
`;

export function Section8() {
  return (
    <SectionContainer>
      <div className="flex justify-center">
        <div className="md:w-10/12">
          <h2>
            Z oprogramowania Symfonia codziennie{" "}
            <span className="text-green">korzystają tysiące firm</span>{" "}
            z&nbsp;branży deweloperskiej, budowlanej i&nbsp;finansowej.
          </h2>
        </div>
      </div>
      <Grid>
        <GridCol>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Symfonia" />
          </a>
        </GridCol>
        <GridCol>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Symfonia" />
          </a>
        </GridCol>
        <GridCol>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Symfonia" />
          </a>
        </GridCol>
        <GridCol>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Symfonia" />
          </a>
        </GridCol>
        <GridCol>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Symfonia" />
          </a>
        </GridCol>
        <GridCol>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Symfonia" />
          </a>
        </GridCol>
        <GridCol>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Symfonia" />
          </a>
        </GridCol>
        <GridCol>
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="Symfonia" />
          </a>
        </GridCol>
      </Grid>
    </SectionContainer>
  );
}

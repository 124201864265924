import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SCREENS } from "../../components/responsive";

const SectionContainer = styled.section`
  ${tw`
    pt-12 border-t-2 border-gray-200
  `}
`;

const Container = styled.div`
  ${tw`
    container
  `}
`;

const Frame = styled.iframe`
  min-height: 1200px;
  ${tw`
    m-0 p-0 w-full border-0 max-w-full shadow-xl bg-gray-100
  `}

  @media (min-width: ${SCREENS.md}) {
    min-height: 1100px;
  }
`;

export function Section2() {
  return (
    <SectionContainer id="sec-2">
      <Container>
        <div className="text-center mb-7">
          <h2 className="mb-10 h3">
            Wypełnij poniższy formularz i&nbsp;dowiedz się o&nbsp;cenach
            i&nbsp;pakietach oprogramowania Symfonia.
          </h2>
        </div>
        <Frame
          title="form"
          src="https://symfonia.pl/Formularze/PROD/formLead/?id=DEWELOPERKA_LP_FORM_INDIRECT_THEYPL_GEO&url=[URL]"
        ></Frame>
      </Container>
    </SectionContainer>
  );
}

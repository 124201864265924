import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const SectionContainer = styled.section`
  ${tw`
    container pt-20 text-center
  `}
`;

export function Section3() {
  return (
    <SectionContainer>
      <div className="flex justify-center">
        <div className="md:w-10/12">
          <h2 className="has-ornament">
            Oprogramowanie Symfonia zostało stworzone na&nbsp;potrzeby firm
            z&nbsp;Twojej branży
          </h2>
          <p className="mb-6">
            Deweloperzy i&nbsp;przedsiębiorstwa funkcjonujące w&nbsp;branży
            budowlanej prowadzą jednocześnie wiele projektów i&nbsp;inwestycji,
            do&nbsp;których obsługi potrzeba wyspecjalizowanych narzędzi
            z&nbsp;zakresu zarządzania biznesowego.
          </p>
          <p>
            Z oprogramowaniem Symfonia cały proces związany z&nbsp;ofertowaniem,
            zakupami, negocjacjami, finansowaniem, budową
            aż&nbsp;po&nbsp;sprzedaż i&nbsp;rozliczenie inwestycji może
            przebiegać w&nbsp;prosty i&nbsp;wydajny sposób.
            Dzięki&nbsp;wdrożeniu nowoczesnego oprogramowania weryfikacja
            poprawności danych przebiega znacznie szybciej, a&nbsp;terminy
            realizacji ustawowych obowiązków masz zawsze pod&nbsp;kontrolą.
          </p>
        </div>
      </div>
    </SectionContainer>
  );
}

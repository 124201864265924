import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

interface ButtonProps {
  text: string;
  url: string;
  anchor?: boolean;
}

const Link = styled.a`
  ${tw`
    text-white text-base bg-green font-quicksand font-bold text-center rounded-md max-w-xxs block whitespace-nowrap leading-cta hover:bg-green-700 shadow-xl hover:shadow-md transition-shadow ease-in duration-100
  `}
`;

export function Button(props: ButtonProps) {
  const smoothScroll = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    // Get link href attribute value
    const linkhref: string = (ev.target as HTMLLinkElement).href;
    /**
     * @type {string} Get link href attribute value
     */
    const hash: string = linkhref.substr(linkhref.indexOf("#") + 1);
    /**
     * @type {object} Get section by id
     */
    const sec: HTMLElement = document.getElementById(hash) as HTMLElement;
    // Get header client height
    const headerHeight: number = 0;
    // Offset: ex. Head height
    const offset: number = headerHeight;
    // Get body position
    const bodyRect: number = document.body.getBoundingClientRect().top;
    // Get section position relative
    const sectionRect: number = Math.round(sec.getBoundingClientRect().top);
    // Substract: the section from body;
    const sectionPosition: number = sectionRect - bodyRect - offset;
    // Scroll animation
    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  };

  if (props.anchor)
    return (
      <Link href={props.url} onClick={(ev) => smoothScroll(ev)}>
        {props.text}
      </Link>
    );

  return <Link href={props.url}>{props.text}</Link>;
}
